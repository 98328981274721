import React from 'react';
import Layout from 'components/layout';
import Box from 'components/box';
import Title from 'components/title';
import FullWidth from 'components/fullwidth';

const KdeNasNajdete = () => {
  return (
    <Layout>
      <FullWidth>
        <Box>
          <Title as="h2" size="large">
            Kde nás najdete
          </Title>
        </Box>
        <div className="g1x2 kdenasnajdete">
          <div>
            <figure>
              <strong>Penz a Hadraba v.o.s.</strong>
              <div>
                Čechova 727/52,
                <br />
                370&#8239;01&nbsp;&nbsp;České Budějovice
              </div>
              <div className="phone1">IČO: 28122330</div>
              <div className="phone1">773 729 956</div>
              <div className="phone2">721 638 792</div>
              <div className="email">
                <a href="mailto:penzova@insolvenceph.cz">
                  penzova@insolvenceph.cz
                </a>
              </div>
            </figure>
          </div>
          <div className="map"> 
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2619.4501595702936!2d14.479366251517835!3d48.96395470112004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47734fbf0a574ac9%3A0x46e5d967f31ac60e!2zxIxlY2hvdmEgNzI3LCDEjGVza8OpIEJ1ZMSbam92aWNlIDYsIDM3MCAwMSDEjGVza8OpIEJ1ZMSbam92aWNl!5e0!3m2!1sen!2scz!4v1659976548908!5m2!1sen!2scz"
              width="600"
              height="450"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              title="map"
            ></iframe>
          </div>
        </div>
      </FullWidth>
    </Layout>
  );
};

export default KdeNasNajdete;


